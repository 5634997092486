<template>
    <div id="PrintDom">

        <div class="A4" id="orderDetails2">

            <div class="A4title">
                【V印】自动打印系统
                <!-- <div>Version 2.0.6</div> -->
            </div>

            <div class="printRecord">

                <div>
                    {{ new Date(new Date().getTime() - 1000 * 56 * 5) }} - 根据用户设置开始自动打印...
                </div>

                <div>
                    系统一般按照文件顺序自动打印（装订或者复杂文件除外）
                </div>
                <!-- 
                <div class="afterText">
                    有问题请扫右侧二维码提交售后申请。请确保已经检查过文件内容无问题。
                    <img class="after" src="@\assets\img\after.png" alt="">
                </div> -->




            </div>


            <div class="orderDetails">

                <el-row class="tableTop">
                    <el-col :span="8">
                        订单号：{{ Order.number }}
                    </el-col>
                    <el-col :span="8">
                        付款时间：{{ Order.paidAt }}
                    </el-col>
                    <el-col :span="8">
                        文件总数：{{ FileCount() }} 个
                    </el-col>
                </el-row>


                <el-table :data="Order.Files" border>
                    <el-table-column label="排序" width="62">
                        <template #default="scope"> {{ scope.$index + 1 }} </template>
                    </el-table-column>

                    <el-table-column label="文件名" min-width="120">
                        <template #default="scope">
                            <div :class="{ filename: scope.row.oldfilename.length > 10 }">{{ scope.row.oldfilename }}</div>
                        </template>
                    </el-table-column>


                    <el-table-column label="纸张" width="80">
                        <template #default="scope">


                            <span :class="{ strong2: scope.row.material != '70g复印纸' }">
                                {{ scope.row.material.replace("复印", '') }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="尺寸" width="80">
                        <template #default="scope">

                            <span :class="{ strong2: scope.row.size != 'A4' }">
                                {{ scope.row.size }}
                            </span>
                        </template>

                    </el-table-column>
                    <el-table-column label="颜色" width="100">
                        <template #default="scope">
                            <span :class="{ strong: scope.row.style != '黑白' }">
                                {{ scope.row.style }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="单双面" width="80">
                        <template #default="scope">
                            <span :class="{ strong2: scope.row.double != '双面' }">
                                {{ scope.row.double }}
                            </span>
                        </template>


                    </el-table-column>

                    <!-- <el-table-column label="价格" width="90">
                        <template #default="scope"> {{ scope.row.totalPrice }} 元 </template>
                    </el-table-column> -->
                    <!-- <el-table-column label="缩放" prop="scale" width="120" /> -->

                    <el-table-column label="页数/纸张数" width="140">
                        <template #default="scope"> 
                            <div v-if="scope.row.scale == '每版打印1页'">
                                {{ scope.row.pageCount }}页 / {{scope.row.double == '双面' ? Math.round(scope.row.pageCount / 2) : scope.row.pageCount }}张纸
                            </div>
                            
                            <div v-if="scope.row.scale != '每版打印1页'">
                                {{ scope.row.pageCount }}页 / {{Math.ceil((scope.row.double == '双面' ? Math.round(scope.row.pageCount / 2) : scope.row.pageCount)/(scope.row.scale.replace("每版打印", "").replace("页", "")))  }}张纸 
                     
                                <div class="strong2">
                                    {{ scope.row.scale }}
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="份数" prop="count" width="65" />
                    <el-table-column label="装订方式" width="120">
                        <template #default="scope">
                            <span :class="{ strong2: scope.row.binding != '不装订' }">
                                {{ scope.row.binding }}
                            </span>
                            <!-- <div  class="coverColorFonT" :style="{  color: 'white',fontWeight:'700',backgroundColor:getCoverColor(scope.row.coverColor) }" v-if="scope.row.binding == '皮纹纸胶装'">{{
                                scope.row.coverColor }}</div> -->
                            <div class="coverColorFonT"
                                :style="{ color: getCoverColor(scope.row.coverColor), fontWeight: '700' }"
                                v-if="scope.row.binding == '皮纹纸胶装'">{{
                                    scope.row.coverColor }}</div>
                            <div class="fontCover coverColorFonT" v-if="scope.row.binding == '皮纹纸胶装'"
                                :style="{ backgroundColor: getCoverColor(scope.row.coverColor) }">{{ scope.row.coverContent
                                }}</div>
                            <div class="fontCover" v-if="scope.row.coverText && scope.row.coverContent == '文字封面'">
                                印字：{{ scope.row.coverText }}</div>
                        </template>
                    </el-table-column>


                </el-table>

            </div>

            <div class="printRecord">


                <!-- <div class="blessing">
                    祝您学业有成！
                </div> -->


            </div>


        </div>
        <el-button style="margin-top: 50px;" @click="exportPNG()">测试</el-button>
        <el-button style="margin-top: 50px;" v-print="print">打印清单</el-button>
    </div>
</template>
    


<script setup >
import { onMounted, reactive, ref } from 'vue'
import store from '@/store'
import html2canvas from "html2canvas"

const props = defineProps({
    Order: { type: Object, default: true },
})

// window.print()
const FileCount = () => {
    if (!props.Order.Files) return 0
    return props.Order.Files.length
}

// 导出png
const exportPNG = () => {

    return new Promise((resolve, reject) => {
        const HTMLElement = document.getElementById('orderDetails2');
        var width = HTMLElement.offsetWidth; //获取dom 宽度
        var height = HTMLElement.offsetHeight; //获取dom 高度
        var canvas = document.createElement("canvas"); //创建一个canvas节点
        var scale = 3; //定义任意放大倍数 支持小数
        canvas.width = width * scale; //定义canvas 宽度 * 缩放
        canvas.height = height * scale; //定义canvas高度 *缩放
        canvas.getContext("2d").scale(scale, scale); //获取context,设置scale 
        canvas.dpi = 500

        var opts = {
            //      scale: scale, // 添加的scale 参数
            canvas: canvas, //自定义 canvas
            // logging: true, //日志开关，便于查看html2canvas的内部执行流程
            width: width * 2, //dom 原始宽度
            height: height * 2,
            dpi: 500,

            useCORS: true // 【重要】开启跨域配置
        };

        html2canvas(HTMLElement, opts).then((canvas) => {
            const contentWidth = canvas.width;
            const contentHeight = canvas.height;
            const ctx = canvas.getContext('2d');

            // 【重要】关闭抗锯齿
            ctx.mozImageSmoothingEnabled = false;
            ctx.webkitImageSmoothingEnabled = false;
            ctx.msImageSmoothingEnabled = false;
            ctx.imageSmoothingEnabled = false;


            const imgUrl = canvas.toDataURL('image/webp', 1);
            const tempLink = document.createElement('a'); // 创建一个a标签
            tempLink.style.display = 'none';
            tempLink.href = imgUrl;
            tempLink.setAttribute('download', props.Order.number); // 给a标签添加下载属性
            if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank');
            }
            document.body.appendChild(tempLink); // 将a标签添加到body当中
            tempLink.click(); // 启动下载
            document.body.removeChild(tempLink); // 下载完毕删除a标签
            window.URL.revokeObjectURL(imgUrl);

            let file = {}
            file.style = "黑白"
            file.double = "单面"
            file.binding = "不装订"
            file.filename = props.Order.number + ".webp"
            file.oldfilename = props.Order.number + ".webp"
            file.fileSize = 1
            file.type = "img"
            file.uploadMethod = "清单打印"
            file.size = "A4"
            file.scale = '每版打印1页'
            file.range = "全部"
            file.count = 1
            file.pageCount = 1
            file.id = 1
            file.range = "全部"
            file.filePath = `C:\\Users\\Administrator\\Downloads\\${props.Order.number}.webp`
            //   file.pdffilePath = `C:\Users\Administrator\Downloads\PdfDirectory`
            file.url = file.filePath
            props.Order.Files.unshift(file)
            resolve('ok')
        })
    })

}
store.state.exportPNG = exportPNG

const getCoverColor = (color) => {
    if (!store.state.initData) store.state.initData = {}
    for (let c of store.state.initData.coverColor) {
        if (color == "白色") return "#666"
        if (c.name == color) return c.color
    }
}

const print = ref({
    id: 'orderDetails2',//这里的id就是上面我们的打印区域id，实现指哪打哪
    popTitle: '配置页眉标题', // 打印配置页上方的标题
    extraHead: '', // 最上方的头部文字，附加在head标签上的额外标签，使用逗号分割
    preview: false, // 是否启动预览模式，默认是false
    previewTitle: '预览的标题', // 打印预览的标题
    previewPrintBtnLabel: '预览结束，开始打印', // 打印预览的标题下方的按钮文本，点击可进入打印
    zIndex: 20002, // 预览窗口的z-index，默认是20002，最好比默认值更高
    previewBeforeOpenCallback() { console.log('正在加载预览窗口！'); }, // 预览窗口打开之前的callback
    previewOpenCallback() { console.log('已经加载完预览窗口，预览打开了！') }, // 预览窗口打开时的callback
    beforeOpenCallback() { console.log('开始打印之前！') }, // 开始打印之前的callback
    openCallback() { console.log('执行打印了！') }, // 调用打印时的callback
    closeCallback() { console.log('关闭了打印工具！') }, // 关闭打印的callback(无法区分确认or取消)
    clickMounted() { console.log('点击v-print绑定的按钮了！') },

})


</script>
    
    
  
<style lang="less">
#PrintDom {
    display: flex;
}

.A4 {
    width: 1000px;
    height: 1300px;
    //   background-color: rgb(243, 243, 243);
    margin: 20px;
    border-radius: 8px;

    .el-table .el-table__body-wrapper .el-table__cell {

        padding: 8px 0px !important;
        /* min-width: 0; */
        /* box-sizing: border-box; */
        /* text-overflow: ellipsis; */
        /* vertical-align: middle; */
        /* position: relative; */
        /* text-align: left; */
        z-index: 1;
        color: #000;
        //   border-bottom: 0.5px solid rgb(71, 71, 71) !important;
        //  border-right: 0.5px solid rgb(71, 71, 71) !important;
    }
}

.A4title {
    font-size: 29px;
    font-weight: 700;
    text-align: center;
    margin: 30px;
    margin-top: 100px;
}

.orderDetails {
    padding: 30px;
}

.strong {
    font-size: 15px !important;
    font-weight: 700;
    color: #07C160;
}

.strong2 {
    font-size: 15px !important;
    font-weight: 700;
    color: #00000091;
}

.printRecord {
    margin-left: 30px;
    font-size: 15px;
    line-height: 32px;
    color: rgb(0, 0, 0);
}

.tableTop {
    margin-bottom: 20px;
    font-weight: 700;
    color: rgb(0, 0, 0);
}

.filename {
    font-size: 12px;
    line-height: 15px !important;
}

.after {
    width: 80px;
    float: right;
    margin-right: 50px;
}

.afterText {
    line-height: 80px;
}

.blessing {
    font-size: 14px;
    color: #666;
    text-align: center;
}

.fontCover {
    font-size: 12px;
}

.coverColorFonT {
    border-radius: 4px;
    color: white;
}
</style>
    